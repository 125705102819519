import styled from "styled-components";

export default styled.div`
display: flex;
justify-content: center;
width: 100%;
height: 420px;
width: 100%;
border-radius: 10px;
overflow-y: auto;

box-shadow: 0px 0px 11px -1px #000000;
padding-top: 30px;
padding-left: 20px;
padding-right: 20px;
margin-top: 10px;
margin-right: 10px;
margin-left: 10px;
margin-bottom: 10px;
`;

{/*
display: flex;
justify-content: center;
align-items: center;
height: 250px;
width: 100%;
background-color: #683bb7;
color: #fff;
margin: 15px;
font-size: 4em;

*/}