import "./App.css";

import React, { useState } from "react";
import { Link } from "react-scroll";
import { BsTelegram } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { ImQuotesLeft } from "react-icons/im";

//import thum from "./assets/photo.png";
//import { AiOutlineDoubleLeft } from "react-icons/ai";
//import { AiOutlineDoubleRight } from "react-icons/ai";

//import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";

import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ReactDOM from "react-dom";
// import { Component } from "react";
import Carousel from "react-elastic-carousel";
import Item from "./Items.js";
///for horizontal scroll

import "./styles.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

function App() {
  const [active, setActive] = useState("nav_menu");
  const [icon, setIcon] = useState("nav_toggler");

  const navToggle = () => {
    if (active === "nav_menu") {
      setActive("nav_menu nav_active");
    } else setActive("nav_menu");

    if (icon === "nav_toggler") {
      setIcon("nav_toggler toggle");
    } else setIcon("nav_toggler");
  };

  const [items] = useState([
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        Given how enjoyable and at the same time informative the sessions really
        were, I can proudly say that your mentorship has been the main pillar
        amongst all the other sessions I had taken online. The fact that all the
        syllabus given at the beginning of the course were fully covered in a
        way anyone without prior knowledge of trading can possibly understand
        and that each technical aspects and terms were assessed with online
        classwork and progressive assignments really made this mentorship stand
        out. More importantly, the mentorship will give you a completely new
        perspective on trading - it will once and for all engraves in you that
        trading is an international and professional skillset that should be
        learnt, practiced and applied with proper risk management and adequately
        tested trading plan and that it is not an online ATM machine like how
        some internet scammers make it sound like. I really did enjoy the
        mentorship and looking forward to be mentored again on an intermediate
        to advanced level.
      </p>
      <p>@Efa232e</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        I will always be grateful to you for your support and kindness my mentor
        Zach. It would be impossible to count all the ways you've helped me in
        my career. Thank you so much for all that you've done — I only hope I
        can return the favor sometime in the future. Thanks for being a good
        mentor and for guiding me on the right path.I paid few ,I learnt
        massive.You are a good mentor . The respectful toward your
        students,enthusiastic on helping achieving our goal.Every lesson i have
        taken were helpfull. You helped me on achieving my goal best for you!!
      </p>
      <p>@grandemoss</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        Its an eye-opening course with real examples and try outs, most of the
        courses we found online are uncategorized and unorganized. But the
        lessons we learned from you are very well organized; from the basic
        principles up to an advancement. Personally, I was more of gambling
        rather than trading before I took your lessons and thanks to you now I
        can confidently say that I've become a profitable trader.
      </p>
      <p>@Light222</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        My review on my trading session for the last two months was an eye
        opener on what’s really happening on the market and why is it acting
        that way. The things l’ve learned from my classmates are the most
        crucial part coz I can see what they are doing and learn from their
        experiences as well as their trade charts. But mostly I want to thank
        you, my teacher Zach for ur carful attention u given to us!!
      </p>
      <p>@dior_ds</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        I watched many videos on YouTube and cryptoet on tiktok and I've traded
        for around 5-6 months before you started the mentorship program I've
        been liquidated 3 times and i was learning through trial and error but
        it was costing me considerable amount of money and when i saw you're
        starting the mentorship i was excited and i registered and everything
        changed!......i was impressed even on the first week it was enlightening
        and since then i improved a lot.....thanks to you i learned a lot in
        short time which reduces the time and money it would have taken to be
        where i am now ....you showed me a clear path to the top now I'm
        confident that i will get to be a successful trader! And i will thank
        you for the opportunity you gave us to share your knowledge and
        experiences!
      </p>
      <p>@RobelMekuriya</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        First time when i contact you anidm ngr alakm nbr wela candle mnamn
        hulunm ngr ke 0 nbr yetenesahut, ahun hulunm ngr awukialehu malet sayhon
        betam progress alegn bzu ngr awukebetal kalefew session your lecture was
        amazing beka betam bzu ngr nw yetemarkubt class bcha sayhon disciplinem
        chmr, perfect nbr mnm ngr satkenes bezih ketilbet zach bro your tsebay
        also amazing betam tihut nh mnm ngr satiselech nw yastrmarkn betam des
        blogn nw yechereskut lemn aleke eyalku antn silawekuh betam destegna
        negn bro bezih ketilbet behulum ngr zach 🙏🙏🙏
      </p>
      <p>@Wendixo</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        It was a great time, I never had this kind of feeling for class really
        it was like drinking water and getting satisfied. I recommend Gov'ts
        this kind of way to teach. The new generation will be more valuable .
        Thank you so much Zach you so kind and honest I really appreciate your
        strategy plans and way of sharing what you have.
      </p>
      <p>Samyoha trading</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        I found this course very well structured for both beginners and
        intermediate level traders. In the course, traders will get basic
        financial knowledge, different types of analysis (fundamental and
        technical) and deep trading strategies for different assets. As a
        beginner, I have got immense understanding of the financial market that
        allowed me to test my knowledge and skills on demo account and them to
        real. I want to appreciate Abrish for his excellent facilitation and
        friendly support during the course and beyond.
      </p>
      <p>@GirmaAragaw</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        The training for me personally was very great and i learned so much.
        while being interesting it also gave me very significant ways of viewing
        things related to trading we went by schedule and covered topics from
        simple introduction to ways of being a trader to advanced level. The
        exercise and assignment that we were given made us explore beyond the
        lesson and in class time having an open discussion helped me to see how
        other traders operate and it gave me a guideline on being a trader
        overall the journey was very interesting and helpful and full of many
        important great lessons and im so appreciative and thankful for being a
        part of it.
      </p>
      <p>@Natykilla</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        This mentorship helped me a lot to learn and explore about not only
        trading but finance in general too, when i join this mentorship my
        thought was trading was only crypto, forex and stocks but truth is far
        more than that, i didn't know any thing about the financial system, how
        it works, how we ended up in this system, what's a recession, i enjoyed
        every sessions And the way you put every topic in to pieces to explain
        in the way that we can understand, the time you give to review our
        assignments (i know its not easy to review 40+ assignments 3 times a
        week) and when we started the class the paln was to finish it within 2
        months and it took us i think 3 months and that helped us to understand
        more Overall i give this program 9/10 i deducted 1 point because the
        mentorship ended just kidding it's 10+/10. Thanks abresh for being a
        fuel for my trading journey.
      </p>
      <p>@Yeenegus</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        My experience in class with Abrish was a grate class he helped me to
        know how money goes from basic to advanced when I start the class I
        can't even read trading charts after the class I am able to know well
        how to read charts as well as how to trade. Abrish helped me a lot he
        will review my progress every time and give me feed back and give me an
        assignment to practice more i was happy to have a grate mentor like
        Abrish. Abrish is a Highly recommended mentor for a person who want to
        be profitable trader.{" "}
      </p>
      <p>@Franolgeleta</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        For me am grateful to get this chance and you laid a step ston for me in
        the world of trading and the class were amazing i enjoyed this class
        more any other in my life time and for nxt i will be waiting for another
        more detailed mentorship and for longer time than 2 month and thanks 🙏🏽
      </p>
      <p>@Redox_k</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        I have to say one thing about the session and the training, it was a
        very enlightening and also helped me in a way to treat trading as a
        legitimate Skill to be developed through time and practice plus The
        fundamental and technical things you taught us were top notch 👌......In
        the meantime i am on my way to pass a prop firm challenge just and
        already passed phase 1 ......Thank u zack
      </p>
      <p>@Abdulex_9</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        You r the best ever mentor bro .u showed me z new path of getting money
        and building assets .zack because of u, im consistantly getting daily 20
        -30 usd. Thank u with BIG respect.
      </p>
      <p>@Mufid544</p>
    </div>,
    <div>
      <ImQuotesLeft size="2rem" color="#007bff" />
      <p>
        I joined your training as a complete beginner not knowing a thing about
        the trading world and I left with a lot of knowledge which helped me
        level up really quick. I liked that all the lessons were organized and
        had a lot of resources too so thank you for everything Zach 🙏🏻
      </p>
      <p>@Mossey_22</p>
    </div>,
  ]);

  return (
    <div className="wholebody">
      <div id="main">
        <div className="background">
          <nav className="nav">
            <div class="logo">
              <Link to="main">ETC Library</Link>
            </div>
            <ul className={active}>
              <li className="nav_item">
                <Link to="main">Home</Link>
              </li>
              <li className="nav_item">
                <Link to="about">About</Link>
              </li>
              <li className="nav_item">
                <Link to="testimonies">Testimonials</Link>
              </li>
              <li className="nav_item">
                <Link to="register">Register</Link>
              </li>
              <li className="nav_item">
                <Link to="contact">Contact</Link>
              </li>
            </ul>
            <div onClick={navToggle} className={icon}>
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
          </nav>

          <div className="text-note">
            <div className="learn-div">
              {" "}
              <p> Learn Earn Repeat </p>
            </div>
            <div className="explore">
              <p>Explore new ideas and business opportunities</p>
            </div>
          </div>
        </div>
      </div>

      <div id="about">
        <div className="about-place">
          <div className="title-a">
            <h1>About the course</h1>
          </div>
          {/*
          <div className="video-part">

          
            <div class="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/8GNOV48lWVI" title="Detail about our training program" allowFullScreen></iframe>
            </div>

            <iframe width="100%" height="450" src="https://www.youtube.com/embed/8GNOV48lWVI" title="Detail about our training program" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

          </div>
  */}

          <Container>
            <div class="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/8GNOV48lWVI"
                title="Detail about our training program"
                allowFullScreen
              ></iframe>
            </div>
          </Container>
        </div>
      </div>
      <div id="register">
        <div className="register-place">
          <div className="title-r">
            <h1>How to Register ?</h1>
          </div>

          <div className="text-area">
            {/* 
            <ul>
              <p>To register for the upcoming class please send us: </p>
              <li>Full Name</li>
              <li>Phone number</li>
              <li>Telegram username</li>
              <p>
                To the telegram account which is linked below <br />
                <b>Deadline June 29</b>
              </p>
            </ul>
*/}
            {/*
            <ul>
            <p>
              <b>Sorry, all spots are full for this round!</b> <br />
              Registration for the 5th round has ended on Sunday(October 29)! <br />
              Details regarding the next round training will be posted on the channel, stay tuned!
            </p>
            </ul>
*/}
            <div className="registration-message">
              <p>
                <b>Sorry, all spots are full for this round!</b>
              </p>
              <p>
                Registration for the 5th round has ended on{" "}
                <b>Sunday (October 29)! </b>
              </p>
              <p>
                Details regarding the next round training will be posted on the
                channel, stay tuned!
              </p>
            </div>
            {/* <div className="registration-message">
              <p>
                To register for the 5th mentorship program starting on{" "}
                <span className="bold_oct">October 30</span>, please provide the
                following details:
              </p>
              <li>Full Name</li>
              <li>Phone number</li>
              <li>Telegram username</li>
              <p>
                Please provide this information to the telegram account linked
                below
              </p>
              <p>
                <b className="red_text">Limited spots available</b>
              </p>

              <p>
                <b className="discount">Registration ends on October 29</b>
              </p>
            </div> */}
          </div>

          {/* <a href="https://t.me/ZakCS00" className="register_button">
            <div className="button_align">REGISTER NOW</div>
          </a> */}

          <a href="https://t.me/ETCLibrary" className="register_button">
            <div className="button_align">Telegram Channel</div>
          </a>
        </div>
      </div>

      <div id="testimonies">
        <div className="testimonial-place">
          <div className="title-t">
            <h1>Testimonials</h1>
          </div>

          <hr className="seperator" />
          <div className="carousel-wrapper">
            <Carousel breakPoints={breakPoints}>
              {items.map((item) => (
                <Item key={item}>{item}</Item>
              ))}
            </Carousel>
          </div>
          {/*   
          <div class="main-scroll-div">
            <div>
              <button class="icon" onclick="scrolll()">
                <AiOutlineDoubleLeft />
              </button>
            </div>
            <div class="cover">
*/}
        </div>

        {/*
            <div>
              <button class="icon" onclick="scrollr()">
                <AiOutlineDoubleRight />
              </button>
            </div>
             */}
      </div>
      {/*
        </div>
      </div>
                    */}

      <div className="right-bottom">
        {/* <a href="https://t.me/ZakCS00" className="chat_button">
          {" "}
          <div className="button_align">
            <b>REGISTER NOW</b>
          </div>
        </a> */}

        <a href="https://t.me/ETCLibrary" className="chat_button">
          {" "}
          <div className="button_align">
            <b>Telegram Channel</b>
          </div>
        </a>
      </div>
      <div id="contact">
        <div className="contact-place">
          <ul class="contact-list">
            <li>
              <a href="https://t.me/ETCLibrary">
                <BsTelegram size="2rem" color="#ffffff"></BsTelegram>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCBh4esKOTnloy4B_z663g5Q">
                <AiFillYoutube size="2rem" color="#ffffff"></AiFillYoutube>
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@cryptoet_">
                <FaTiktok size="2rem" color="#ffffff"></FaTiktok>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/cryptoet_ig/">
                <AiFillInstagram size="2rem" color="#ffffff"></AiFillInstagram>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

export default App;
